import React from 'react';
import Plot from 'react-plotly.js';

const Chart = ({ dates, open, high, low, close, volume }) => {
  return (
    <Plot
      data={[
        {
          x: dates,
          open: open,
          high: high,
          low: low,
          close: close,
          type: 'candlestick',
          xaxis: 'x',
          yaxis: 'y',
          name: 'Price'
        },
        {
          x: dates,
          y: volume,
          type: 'bar',
          xaxis: 'x',
          yaxis: 'y2',
          opacity: 0.5,
          name: 'Volume'
        }
      ]}
      layout={{
        grid: { rows: 2, columns: 1, heights: [0.7, 0.3], pattern: "independent" },
        xaxis: {
          domain: [0, 1],
          anchor: 'y',
          rangeslider: { visible: false } // Disables the range slider
        },
        yaxis: { domain: [0.4, 1], //title: 'Price' 
        }, // Top chart adjusted to start from 0.4
        yaxis2: { domain: [0, 0.3], //title: 'Volume' 
          }, // Bottom chart adjusted to end at 0.3
        margin: { t: 10, b: 10, l: 40, r: 10 }, // Optional margin adjustments
        showlegend: false,
      }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default Chart;
