
//const API_BASE = 'http://localhost:8000'; // Adjust if needed
export const API_BASE = 'https://dev02.mattiabiancaterra.com/api';

// export async function fetchData(ticker) {
//   const response = await axios.get(`${API_BASE}/api/data/${ticker}`);
//   return response.data;
// }

export async function fetchData(ticker) {
  try {
      const response = await fetch(`${API_BASE}/data/${ticker}`);
      return await response.json();
  } catch (error) {
      console.error(`Error fetching data for ${ticker}:`, error);
      throw error;
  }
}