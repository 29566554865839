import React, { useEffect, useState } from 'react';
import { fetchData } from '../services/api';
import Chart from '../components/Chart';
import RSIChart from '../components/RSIChart';
import './Dashboard.css';

function Dashboard() {
  const [tickers, setTickers] = useState(['ES=F', 'MSFT', 'CL=F', "AAPL"]);
  const [dataMap, setDataMap] = useState({});
  const [loadingMap, setLoadingMap] = useState({});
  const [errors, setErrors] = useState([]);
  const [newTicker, setNewTicker] = useState('');

  const fetchTickerData = async (ticker) => {
    setLoadingMap((prev) => ({ ...prev, [ticker]: true }));
    try {
      const data = await fetchData(ticker);
      if (data) {
        setDataMap((prevDataMap) => ({
          ...prevDataMap,
          [ticker]: data,
        }));
        setErrors((prevErrors) => prevErrors.filter((err) => err !== ticker));
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      console.error(`Error fetching data for ${ticker}:`, error);
      setErrors((prevErrors) => [...prevErrors, ticker]);
    } finally {
      setLoadingMap((prev) => ({ ...prev, [ticker]: false }));
    }
  };

  const handleAddTicker = () => {
    const upperTicker = newTicker.trim().toUpperCase();
    if (upperTicker && !tickers.includes(upperTicker)) {
      setTickers((prevTickers) => [...prevTickers, upperTicker]);
      fetchTickerData(upperTicker);
    }
    setNewTicker('');
  };

  const handleRemoveTicker = (tickerToRemove) => {
    setTickers((prevTickers) => prevTickers.filter((ticker) => ticker !== tickerToRemove));
    setDataMap((prevDataMap) => {
      const { [tickerToRemove]: _, ...rest } = prevDataMap;
      return rest;
    });
    setLoadingMap((prev) => {
      const { [tickerToRemove]: _, ...rest } = prev;
      return rest;
    });
    setErrors((prevErrors) => prevErrors.filter((err) => err !== tickerToRemove));
  };

  useEffect(() => {
    tickers.forEach((ticker) => {
      if (!dataMap[ticker] && !loadingMap[ticker]) {
        fetchTickerData(ticker);
      }
    });
  }, [tickers]);

  return (
    <div>
      <div className="top-bar">
        <div className="add-ticker">
          <input
            type="text"
            value={newTicker}
            onChange={(e) => setNewTicker(e.target.value)}
            placeholder="Add ticker (e.g., GOOG)"
          />
          <button onClick={handleAddTicker}>Add</button>
        </div>
        <div className="ticker-tags">
          {tickers.map((ticker) => (
            <div className="ticker-tag" key={ticker}>
              {ticker}
              <button className="remove-ticker" onClick={() => handleRemoveTicker(ticker)}>
                X
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="charts-section">
        <div className="charts-container">
          {tickers.map((ticker) => {
            const data = dataMap[ticker];
            const isLoading = loadingMap[ticker];
            const hasError = errors.includes(ticker);

            return (
              <div className="ticker-card" key={ticker}>
                <h2>{ticker}</h2>
                {isLoading && <p>Loading data for {ticker}...</p>}
                {hasError && <p style={{ color: 'red' }}>Failed to load data for {ticker}.</p>}
                {data && (
                  <>
                    <Chart 
                      dates={data.dates} 
                      open={data.open} 
                      high={data.high}
                      low={data.low}
                      close={data.close}
                      volume={data.volume}
                    />
                    <RSIChart 
                      dates={data.dates}
                      rsi={data.rsi}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {errors.length > 0 && (
        <div className="error-message">
          <p>Failed to fetch data for: {errors.join(', ')}</p>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
