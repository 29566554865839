import React from 'react';
import Plot from 'react-plotly.js';

const RSIChart = ({ dates, rsi }) => {
  return (
    <Plot
      data={[
        {
          x: dates,
          y: rsi,
          type: 'scatter',
          mode: 'lines',
          line: { color: 'blue' },
          name: 'RSI'
        }
      ]}
      layout={{
        margin: { t: 10, r: 10, b: 20, l: 40 },
        yaxis: { range: [0, 100], title: 'RSI' },
        //xaxis: { title: 'Date' },
        shapes: [
          { // Overbought line at 70
            type: 'line',
            xref: 'paper',
            x0: 0, x1: 1,
            y0: 70, y1: 70,
            line: { dash: 'dash', color: 'red' }
          },
          { // Oversold line at 30
            type: 'line',
            xref: 'paper',
            x0: 0, x1: 1,
            y0: 30, y1: 30,
            line: { dash: 'dash', color: 'green' }
          }
        ],
      }}
      style={{ width: "100%", height: "100px" }}
      config={{ displayModeBar: false }}
    />
  );
};

export default RSIChart;

